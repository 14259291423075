import _toConsumableArray from "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\@babel\\runtime\\helpers\\esm\\toConsumableArray.js";
import _defineProperty from "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\@babel\\runtime\\helpers\\esm\\defineProperty.js";
import "vant/es/image-preview/style";
import _ImagePreview from "vant/es/image-preview";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/card/style";
import _Card from "vant/es/card";
import "vant/es/pull-refresh/style";
import _PullRefresh from "vant/es/pull-refresh";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
import "vant/es/button/style";
import _Button from "vant/es/button";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { casesList } from '@/api/store';
export default {
  name: 'Home',
  components: (_components = {}, _defineProperty(_components, _Button.name, _Button), _defineProperty(_components, _Tabbar.name, _Tabbar), _defineProperty(_components, _TabbarItem.name, _TabbarItem), _defineProperty(_components, _Swipe.name, _Swipe), _defineProperty(_components, _SwipeItem.name, _SwipeItem), _defineProperty(_components, _List.name, _List), _defineProperty(_components, _PullRefresh.name, _PullRefresh), _defineProperty(_components, _Card.name, _Card), _defineProperty(_components, _Tag.name, _Tag), _defineProperty(_components, _ImagePreview.Component.name, _ImagePreview.Component), _components),
  data: function data() {
    return {
      tabbarActive: 'case',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      filterData: {
        limit: 10,
        page: 0
      }
    };
  },
  methods: {
    // 列表
    getList: function getList(refresh) {
      var _this = this;

      var _self = this;

      casesList(this.filterData).then(function (res) {
        if (res.error.code === 0) {
          // 加载状态结束
          _self.loading = false; // 数据全部加载完成

          if (refresh === 'refresh') {
            var tempList = res.data.list;
            tempList.forEach(function (element, index, array) {
              element.title = '案例' + (index + 1) + '：' + element.title;
            });
            _this.list = tempList;
          } else {
            var _tempList = [].concat(_toConsumableArray(_this.list), _toConsumableArray(res.data.list));

            _tempList.forEach(function (element, index, array) {
              element.title = '案例' + (index + 1) + '：' + element.title;
            });

            _this.list = _tempList;
          }

          if (_this.list.length >= res.data.totalCount) {
            _self.finished = true;
          } else {
            _self.finished = false;
          }
        }
      });
    },
    onLoad: function onLoad() {
      this.filterData.page += 1;
      this.getList();
    },
    onRefresh: function onRefresh() {
      this.refreshing = false;
      this.finished = true; // 防止下拉刷新发送了两次请求的问题

      this.filterData.page = 1;
      this.getList('refresh');
      this.list = [];
    },
    // 切换菜单
    changeTabbar: function changeTabbar(e) {
      if (e === 'home') {
        this.$router.push({
          name: 'home'
        });
      } else if (e === 'case') {
        this.$router.push({
          name: 'case'
        });
      } else if (e === 'package') {
        this.$router.push({
          name: 'package'
        });
      } else if (e === 'personal') {
        this.$router.push({
          name: 'personal'
        });
      }
    },
    // 展开详情
    showDetail: function showDetail(item) {
      _ImagePreview({
        images: item.img_list,
        closeable: true
      });
    }
  }
};