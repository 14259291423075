var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("van-image", {
        attrs: {
          width: "100%",
          height: "225",
          src: require("@/assets/case_header.jpg"),
        },
      }),
      _c("div", { staticClass: "goods-list__title" }, [
        _vm._v("点击案例看验光报告和养眼记录"),
      ]),
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function ($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing",
          },
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text":
                  _vm.list.length === 0 ? "暂无数据" : "没有更多了",
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.list, function (item) {
              return _c("van-cell", { key: item.id }, [
                _c("div", { attrs: { slot: "default" }, slot: "default" }, [
                  _c(
                    "div",
                    { staticClass: "goods-container" },
                    [
                      _c(
                        "van-row",
                        {
                          staticClass: "content-box",
                          nativeOn: {
                            click: function ($event) {
                              return _vm.showDetail(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "content-name" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "van-tabbar",
        {
          staticClass: "page-tottom-tabbar",
          on: { change: _vm.changeTabbar },
          model: {
            value: _vm.tabbarActive,
            callback: function ($$v) {
              _vm.tabbarActive = $$v
            },
            expression: "tabbarActive",
          },
        },
        [
          _c("van-tabbar-item", { attrs: { name: "home", icon: "home-o" } }, [
            _vm._v("首页"),
          ]),
          _c("van-tabbar-item", { attrs: { name: "case", icon: "star-o" } }, [
            _vm._v("数据"),
          ]),
          _c("van-tabbar-item", { attrs: { name: "package", icon: "bag-o" } }, [
            _vm._v("订单"),
          ]),
          _c(
            "van-tabbar-item",
            { attrs: { name: "personal", icon: "friends-o" } },
            [_vm._v("我的")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }